.params-type {
    color: var(--dark, #242424);
    font-family: var(----font-family-name);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.params-min-max {
    color: #847374;
    font-family: var(----font-family-name);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
}
.params{
    flex: 1 0 50%; 
    display: flex;
    flex-direction: column;
    padding: 10px;
}
.input-p{
    width: 100%;
    margin-right: 5px;
}
