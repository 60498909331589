.tax-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.tax-text-container {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: center;
    cursor: pointer;
}
