.reset-btn {
    width: 100%;
    color: var(--art, #E41658);
    text-align: right;
    font-family: var(----font-family-name);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
}