.input-p{
    width: 100%;
}

.text-container {
    width: 100%;
    background-color: #BD00450D;
    padding: 10px;
}


