.price {
    color: var(--dark, #242424);
    text-align: right;
    font-family: var(----font-family-name);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    text-align: right;
    padding: 0;
    margin: 0;
}
.price-per-m2 {
    color: var(--neutral-grey-38, #616161);
    text-align: right;
    font-family: var(----font-family-name);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.2px;
    text-align: right;
    padding: 0;
    margin: 0;
    margin-top: 5px;
}
.price-container{
    padding: 0;
    margin-bottom: 5px;
}
.tax-price-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;
    width: 100%;
}

