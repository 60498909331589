.shape {
    background: none;
    border: none;
    height: 50px;
    width: 50px;
    vertical-align: middle;
    flex: 1 0 16%;
    cursor: pointer;
}

.shape:hover {
    background-color: #e74c3cd0; 
  }
.shape:active {
    background-color: red;
}