.shape-preview-container {
    width: 100%;
    height: 600px;
    background-color: var(--bg-1, rgba(189, 0, 69, 0.05));
    padding: 50px;
    text-align: center; 
}

.shape-preview-container img {
    position: absolute;
}

.shape-preview {
    width: 100%;
    height: auto;
    padding: 0;
    display: inline-block;
    margin: 0 auto;

}

.shape-preview svg {
   overflow: visible;
   width: 400px;
   height: 400px;
   padding: 0;
   margin: 0;
  }

  .shape-preview-width, .shape-preview-height {
    position: absolute;
    color: #E41658;
    font-family: var(----font-family-name);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 0;
    margin: 0;
  }
  .shape-preview-height {
    transform: rotate(90deg);
  }