.validation-error {
    width: 100%;
    padding: 3px;
    color: var(--art, #E41658);
    font-family: var(----font-family-name);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    flex-direction: row;
    gap: 3px;
    align-items: center;
}