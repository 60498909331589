.quantity-container {
    display: flex;
    flex-direction: column;
    flex: 1 0 25%;
}

.quantity {
    color: var(--light-foreground-foreground-1, #242424);
    font-family: var(----font-family-name);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.add-to-card-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-end;
    padding: 30px;

}

.cart-btn {
    flex: 1 0 55%;  
}


