.cf-select-container {
    background-color: #BD00450D;
    padding: 10px;
    margin-bottom: 10px;
}
.select-label {
color: var(--dark, #242424);
font-family: var(----font-family-name);
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px;
}
