.price-logger-container {
    width: 100%;
    height: 600px;
    color: var(--dark, #242424);
    font-family: var(----font-family-name);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    padding: 10px;
}

.price-logger-container .elm-title {
    color: red;
    margin-right: 5px;
    font-weight: bold;
}