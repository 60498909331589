.file-container {
    width: 100%;
    background-color: #BD00450D;
    padding: 10px;
    font-family: var(----font-family-name);
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.file-title {
    color: var(--light-foreground-foreground-1, #242424);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}
.file-max {
    color: #847374;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.input-file-container {
    border-radius: 2px;
    background: var(--light-background-background-1, #FFF);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
    padding: 10px;
}